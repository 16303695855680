
import './reactor-cashore.css';
import ReactorHeadInfo from '../../components/head-estim/Head-estim';
import mascotgolden from '../../components/spinner/skin-info/skin-golden.png';
import headmascotgolden from '../../components/spinner/skin-info/head/head-skin-golden.png';
import visgolden from '../../components/spinner/skin-info/golden-vis.png';
import mascotmois from '../../components/spinner/skin-info/skin-mois-cashore.png';
import headmascotmois from '../../components/spinner/skin-info/head/head-mois.png';

import headmascote1 from '../../components/spinner/skin-info/head/head1.png';
import headmascote2 from '../../components/spinner/skin-info/head/head2.png';
import headmascote3 from '../../components/spinner/skin-info/head/head3.png';
import headmascote4 from '../../components/spinner/skin-info/head/head4.png';
import headmascote5 from '../../components/spinner/skin-info/head/head5.png';


import mascotSpinner from '../../components/spinner/skin-spiner-mascot.png';
import Spinner from '../../components/spinner/Spinner';
import ReactorGameNav from '../../components/gameNav/GameNav';


function ReactorCashore() {
  return (
    <>
      <main className="reactor-main">
        <div className="reactor-block-cashore">
          <ReactorGameNav jeu={" > Cashor.exe"}/>
          <div className="reactor-cashore-phrase">
            Profitez d’un moment de détente grâce à cette machine à sous 100% gagnante
          </div>
          <div className="reactor-block-info-cashore">
            <div className="reactor-div-infos-cashore">
              <div className="reactor-div-info-cashore reactor-shadow-info-cashore golden">
                <img className="reactor-img-mascot-golden" src={mascotgolden}></img>
                <div className="reactor-info-cashore-txt">
                  <div className="reactor-info-cashore-txt-titre">
                    G<font className="reactor-color-golden">o</font>lden<br></br> skin
                  </div>
                  <div className="reactor-info-cashore-txt-texte">
                    Lorem ipsum dolor<br></br> sit amet<br></br> consectetur.
                  </div>
                </div>
                <div className="reactor-info-cashore-vis vistopleft">
                  <img className="reactor-img-mascot-golden-vis" src={visgolden}></img>
                </div>
                <div className="reactor-info-cashore-vis vistopright">
                  <img className="reactor-img-mascot-golden-vis" src={visgolden}></img>
                </div>
                <div className="reactor-info-cashore-vis visbottomright">
                  <img className="reactor-img-mascot-golden-vis" src={visgolden}></img>
                </div>
                <div className="reactor-info-cashore-tete">
                  <img className="reactor-img-mascot-golden-head" src={headmascotgolden}></img> x 3
                </div>
              </div>

              <div className="reactor-div-info-cashore reactor-shadow-info-cashore mois">
                <img className="reactor-img-mascot-mois" src={mascotmois}></img>
                <div className="reactor-info-cashore-txt">
                  <div className="reactor-info-cashore-txt-titre">
                  Skin <font className="reactor-color-mois">o</font>f the<br></br> m<font className="reactor-color-mois">o</font>nth
                  </div>
                  <div className="reactor-info-cashore-txt-texte">
                    Lorem ipsum dolor<br></br> sit amet<br></br> consectetur.
                  </div>
                </div>
                <div className="reactor-info-cashore-tete">
                  <img className="reactor-img-mascot-mois-head" src={headmascotmois}></img> x 3
                </div>
              </div>

              <div className="reactor-div-info-cashore reactor-shadow-info-cashore verti">
                <ReactorHeadInfo head={headmascote5} estim={"20"}/>
                <ReactorHeadInfo head={headmascote4} estim={"10"}/>
                <ReactorHeadInfo head={headmascote3} estim={"5"}/>
                <ReactorHeadInfo head={headmascote2} estim={"2"}/>
                <ReactorHeadInfo head={headmascote1} estim={"1"}/>
              </div>
            </div>
            <div className="reactor-div-spinner-cashore">
              <img className="reactor-img-mascot" src={mascotSpinner}></img>
              <Spinner />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ReactorCashore;
