
import './block-tete.css';

function ReactorBlocktete({jeu}) {
  return (
    <>
          <div className="reactor-div-tete-info-detail reactor-border-jeux reactor-Jeu Golden">
            <img className="reactor-img-info-detail-jeux-head" src={`/img-detail/${jeu}-head.png`}></img>
            <div className="reactor-line"></div>
            <img className="reactor-img-info-detail-jeux-head" src={`/img-detail/${jeu}-head.png`}></img>
            <div className="reactor-line"></div>
            <img className="reactor-img-info-detail-jeux-head" src={`/img-detail/${jeu}-head.png`}></img>
          </div>
    </>
  );
}

export default ReactorBlocktete;
