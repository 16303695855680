
import './reactor-main.css';
import ReactorTitrePres from '../../components/titre-presentation/Titre-presentation';
import ReactorTextePres from '../../components/texte-presentation/Texte-presentation';
import ReactPlayer from 'react-player'
import SkinDuMois from '../../components/skin-du-mois/skin-mois.png';
import ombre from '../../components/ombre.png';
import { NavLink } from 'react-router-dom';


function ReactorMain() {


  return (
    <>
      <main className="reactor-main">
        <div className="reactor-pres-div-video">
          <div className="reactor-presentation-video">
            <ReactPlayer
            url='https://www.youtube.com/watch?v=jfKfPfyJRdk'
            controls
            playing
            muted
            width="100%"
            height="100%"
            loop
            />
          </div>
        </div>
        
        <div className="reactor-presentation">
          <div className="reactor-presentation-div">
            <ReactorTitrePres titre={"Bienvenue sur REACTOR"} />
            <ReactorTextePres txt={"mascote"} />
            <NavLink to="/Connexion" className="reactor-main-btn-create-perso">
              Inscrit-toi si t’es badass !
            </NavLink>
          </div>

          <div className="reactor-presentation-skin-mois-block">
            <div className="reactor-presentation-skin-mois-block-titre">
              <div className="reactor-presentation-skin-mois-titre">
                Le Skin du m<font className="reactor-Colorsecond">o</font>is
              </div>
              <div className="reactor-presentation-skin-mois-titre-slogan">
                Vers l'infini et les dollars!
              </div>
              <div className="reactor-presentation-skin-mois-slogan-skin">
                BADBUZZ <br></br>L’ÉCLAIR
              </div>
            </div>
            <div className="reactor-presentation-skin-mois-img-skin">
              <img src={SkinDuMois}></img>
              <img className="react-img-ombre" src={ombre}></img>
            </div>
            <div className="reactor-presentation-skin-mois-block-skin">
            </div>
          </div>
        </div>
      </main>
    </>
      
  );
}

export default ReactorMain;
