
import './jeux.css';
import { useState } from 'react';
import ReactorGameNav from '../../components/gameNav/GameNav';
import ReactorDetailGame from '../../components/detailgame/DetailGame'
import ReactorBtnInfoJeux from '../../components/btn-info-jeux/Btn-Info-Jeux'
import ReactorBtndeplace from '../../components/btn-deplace/Btn-Deplace'

function ReactorJeux() {
  const [jeudetail, setJeuDetail] = useState("Cashor")
  return (
    <>
      <main className="reactor-main page-game">
        <div className="reactor-decalage">
          <ReactorGameNav />
          <ReactorDetailGame jeu={jeudetail} />
          <div className="reactor-block-Jeux">
            <ReactorBtndeplace placement={"gauche"}/>
            <div className="reactor-block-slide-Jeux">
              <ReactorBtnInfoJeux setJeuDetail={setJeuDetail} jeu={"Cashor"}/>
              <ReactorBtnInfoJeux setJeuDetail={setJeuDetail} jeu={"X-Ray"}/>
              <ReactorBtnInfoJeux setJeuDetail={setJeuDetail} jeu={"Fast-Memory"}/>
              <ReactorBtnInfoJeux setJeuDetail={setJeuDetail} jeu={"Coming"}/>
            </div>
            <ReactorBtndeplace placement={"droite"}/>
          </div>
        </div>
      </main>
    </>
  );
}

export default ReactorJeux;
