
import Reactorheader from "./reactor-header";
import ReactorMain from "./pages/home/reactor-main";
import ReactorFooter from "./reactor-footer";
import ReactorCashore from "./pages/Slot-game/reactor-cashore";
import ReactorJeux from "./pages/Jeux/Jeux";
import ReactorBoutique from "./pages/Boutique/Boutique"
import ReactorXRay from "./pages/X-Ray/reactor-x-ray"
import ReactorConnexion from "./pages/Connection/Connexion"
import {
  Route,
  Routes,
  useMatch,
} from "react-router-dom";

  function App() {

    const isHome = useMatch("/");
    const isConnexion = useMatch("/Connexion");
  return (
      <div className="reactor">
        {
          !isConnexion && <Reactorheader />
        }
        
        
        <Routes>
          <Route exact path="/" element={<ReactorMain />} />
          <Route exact path="/Profil" element={"Profil"} />
          <Route exact path="/Jeux" element={<ReactorJeux />} />
          <Route exact path="/Jeux/Cashor" element={<ReactorCashore />} />
          <Route exact path="/Jeux/X-Ray" element={<ReactorXRay />} />
          <Route exact path="/Boutique" element={<ReactorBoutique />} />
          
          <Route exact path="/Connexion" element={<ReactorConnexion />} />
        </Routes>
        {
          !isConnexion && <ReactorFooter />
        }
        
      </div>
  );
}

export default App;
