
import './boutique-affiche.css';
import ReactorLockBlock from '../../components/block-lock/Block-Lock';
import { useState, useEffect } from "react"

  function ReactorBoutiqueAffiche({credits, setCredits, nomSkin, Citation, TypeSkin}) {
    
    const [prix, setPrix] = useState("500")
    
    useEffect(()=>{
        TypeSkin == "mois" && <>
            {
                !isNaN(prix) && setPrix("1000")
            }
        </>
        TypeSkin == "Classic" && setPrix("free")
    }, [prix])


    function acheteSkin () {
        setCredits(credits-prix)
        setPrix("acheté")
    }

    function showbutton(){
        if(!isNaN(prix)){
            document.querySelector(`.Boutique-affiche-block-info.${TypeSkin} .Boutique-affiche-button-achete`).classList.remove("hidde")
        }
    }
    function hidebutton(){
        if(!isNaN(prix)){
            document.querySelector(`.Boutique-affiche-block-info.${TypeSkin} .Boutique-affiche-button-achete`).classList.add("hidde")
        }
    }
  return (
    <> 
    <style>
    {`
        .boutique-affiche-box.${TypeSkin}{
            background-color:var(--color-${TypeSkin});
        }
        .boutique-affiche-box.${TypeSkin}:after{
            background-color:var(--color-${TypeSkin}-back);
        }
        .boutique-affiche-box.mois:after{
            background-color:var(--color-mois);
        }
    `}
</style>
    <div onMouseEnter={showbutton} onMouseLeave={hidebutton} className={`boutique-affiche-div ${TypeSkin}`}>
        <div className={`boutique-affiche-box ${TypeSkin}`}>
            <div className="Boutique-affiche-block-skin ">
                {
                    !isNaN(prix) && <>
                        {
                            TypeSkin == "mois" && <><img className={`Boutique-affiche-skin`} src={`/img-Boutique-affichage/${TypeSkin}.png`}></img></>
                        } 
                        {
                            TypeSkin != "mois" && <> 
                            <ReactorLockBlock />
                            <img className={`Boutique-affiche-skin lockboutique`} src={`/img-Boutique-affichage/${TypeSkin}.png`}></img></>
                        } 
                    </>
                }
                {
                    isNaN(prix) && <img className={`Boutique-affiche-skin`} src={`/img-Boutique-affichage/${TypeSkin}.png`}></img>
                }
                
                {
                    TypeSkin == "mois" && <>
                        <div className='mois-color-box-div'>
                            <div className="mois-color-box boutique-affiche-box-mois">
                                Le Skin<br></br> du Mois
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className={`Boutique-affiche-block-info ${TypeSkin}`}>
                <div className="Boutique-affiche-info-nom">
                    {nomSkin}
                </div>
                <div className="Boutique-affiche-info-cita">
                    {Citation}
                </div>
                <div className={`Boutique-affiche-prix-div ${TypeSkin}`}>
                    {
                        !isNaN(prix) && <>
                            <img className="boutique-affiche-piece" src={`/img-Boutique-affichage/Piece.png`}></img>
                        </>
                    }
                    {
                        isNaN(prix) && <>
                            <img className="boutique-affiche-piece" src={`/img-Boutique-affichage/piece_achete.png`}></img>
                        </>
                    }
                    <div className="Boutique-affiche-prix">
                        {prix}
                    </div>
                </div>
                {
                    !isNaN(prix) && <>
                        <button className={`Boutique-affiche-button-achete hidde`} onClick={() => {if(credits >= prix){acheteSkin()}else{console.log("credits : "+credits)}}}>
                            J’achète
                        </button>
                    </>
                }
                
            </div>
        </div>
    </div>
    </>
  );
}

export default ReactorBoutiqueAffiche;
