import "./boutique.css";
import ReactorBoutiqueAffiche from "../../components/Boutique-affichage/Boutique-Affiche";
import { useState } from "react";

function ReactorBoutique() {
  const [credits, setCredits] = useState(10000);

  return (
    <>
      <main className="reactor-main reactor-main-b">
        <div className="reactor-main-boutique">
          <div className="reactor-nav-boutique">Boutique</div>
          <div className="reactor-container-boutique">
            <div className="reactor-div-boutique">
              <div className="SkinM">
                <ReactorBoutiqueAffiche
                  credits={credits}
                  setCredits={setCredits}
                  nomSkin={"Badbuzz l’éclair"}
                  Citation={"Vers l’infini et les dollars"}
                  TypeSkin={"mois"}
                />
              </div>
              <div className="Skin1">
                <ReactorBoutiqueAffiche
                  credits={credits}
                  setCredits={setCredits}
                  nomSkin={"Classic Reactor"}
                  Citation={"Le héros de tout les univers"}
                  TypeSkin={"Classic"}
                />
              </div>
              <div className="Skin2">
                <ReactorBoutiqueAffiche
                  credits={credits}
                  setCredits={setCredits}
                  nomSkin={"Dark Mustang"}
                  Citation={"Je suis ton géniteur"}
                  TypeSkin={"Dark"}
                />
              </div>
            </div>

            <div className="reactor-div-boutique">
              <div className="Skin3">
                <ReactorBoutiqueAffiche
                  credits={credits}
                  setCredits={setCredits}
                  nomSkin={"Iron Poney"}
                  Citation={"Made in China"}
                  TypeSkin={"Iron"}
                />
              </div>
              <div className="Skin4">
                <ReactorBoutiqueAffiche
                  credits={credits}
                  setCredits={setCredits}
                  nomSkin={"Robocorn"}
                  Citation={"Je suis ton géniteur"}
                  TypeSkin={"Robocorn"}
                />
              </div>
              <div className="Skin5">
                <ReactorBoutiqueAffiche
                  credits={credits}
                  setCredits={setCredits}
                  nomSkin={"Bones"}
                  Citation={"Je suis ton géniteur"}
                  TypeSkin={"Bones"}
                />
              </div>
              <div className="Skin6">
                <ReactorBoutiqueAffiche
                  credits={credits}
                  setCredits={setCredits}
                  nomSkin={"Organ"}
                  Citation={"Je suis ton géniteur"}
                  TypeSkin={"Organ"}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ReactorBoutique;
